import '../scss/app.scss';

// Cockpit token header.

const container = document.querySelector('#content');

const writeCard = ({Autor, Titel, Content},target) => {
  return target.insertAdjacentHTML('beforeend', `
  <div class="col s12 m6">
  <div class="card blue-grey darken-1">
    <div class="card-content white-text">
      <span class="card-title">${Titel}</span>
      <p>${Content}</p>
    </div>
    <div class="card-action">
      <p class="white-text">${Autor}</p>
    </div>
  </div>
  </div>
  `)
};

fetch('https://cockpit.tobsn.me/api/collections/get/Blogpost', {
  headers: { 'Cockpit-Token': 'd35a7983690eedc78a6069e1140aaf' }
})
.then(res=>res.json())
.then(res => {
  console.log(res.entries)
  res.entries.forEach(item => writeCard(item,container));
});